@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');


.code-font {
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
  }

  